import React, { useState } from "react";

const Calculator = () => {

  const [orders, setOrders] = useState("");
  const [value, setValue] = useState("");
  const [savingsPerMonth, setSavingsPerMonth] = useState(0);
  const [savingsPerYear, setSavingsPerYear] = useState(0);

  const handleOrdersChange = (event) => {
    setOrders(event.target.value);
  };

  const handleValueChange = (event) => {
    setValue(event.target.value);
  };

  const calculateSavings = () => {
    const monthlySavings = (orders * value * 0.3).toFixed(2);
    const yearlySavings = (monthlySavings * 12).toFixed(2);


    setSavingsPerMonth(monthlySavings);
    setSavingsPerYear(yearlySavings);
  };

  const handleReset = () => {
    setOrders("");
    setValue("");
    setSavingsPerMonth(0);
    setSavingsPerYear(0);
  };


  return (
    <div name="platforms" className="w-full my-32 overflow-x-hidden">
      <div className="max-w-[1200px] mx-auto ">
        <h2 className="text-4xl mx-6 md:mx-0 md:text-[2.5rem] font-bold text-center font-kumbh">
          Save massive profits from third-party marketplaces
        </h2>

        <div className="flex flex-col md:flex-row gap-2 items-center justify-center mt-14 shadow-xl p-10">
          {/* left  */}
          <div className="md:max-w-[60%] ">
            <div className="flex flex-col gap-4">
              <p className="text-4xl font-kumbh font-semibold max-w-[90%] ">
                Switch to Direct Orders save thousands with Your Own Online
                Ordering System
              </p>
              <p className="text-md font-kumbh">
                Discover potential savings for your food business by using our
                straightforward calculator to estimate the benefits of having
                your own website or app, powered by Fly Eats. Our calculation
                compares it to the average 30% fee charged on marketplaces
              </p>
            </div>

            {/* inputs  */}
            <div className="flex flex-col gap-2 p-5">
              <div className="border-black border-[1px] min-w-[100%] p-1">
                <label className="text-xs font-kumbh p-3 ">
                  Number of Orders per month *
                </label>
                <input
                  type="number"
                  value={orders}
                  onChange={handleOrdersChange}
                  className="bg-transparent focus:outline-none p-3 h-3 w-full"
                />
              </div>
              <div className="border-black border-[1px] min-w-[100%] p-1">
                <label className="text-xs font-kumbh p-3 ">
                  Average order value *
                </label>
                <input
                  type="number"
                  value={value}
                  onChange={handleValueChange}
                  className="bg-transparent focus:outline-none p-3 h-3 w-full"
                />
              </div>
            </div>
            {/* buttons  */}
            <div className="flex flex-row p-5">
              <button onClick={calculateSavings} className="px-8 py-2 rounded-full  text-white border-2 bg-[#007cff] border-[#007cff] hover:bg-transparent hover:text-[#007cff]  duration-300 hover:scale-105">
                Calculate
              </button>
              <button onClick={handleReset} className="px-8 py-2 bg-transparent text-black border-none  bg-[#007cff]  hover:bg-transparent hover:text-red-500  duration-300 hover:scale-105 tracking-wide">
                Reset X{" "}
              </button>
            </div>
          </div>

          {/* right  */}
          <div className="md:max-w-[40%] md:min-w-[40%] md:pl-10 ">
            <div className="flex flex-col gap-2 shadow-2xl p-8">
              <div className="flex flex-col gap-2">
                <p className="text-3xl font-kumbh text-slate-700 font-semibold">
                  Savings/Month
                </p>
                <p className="text-3xl font-kumbh text-[#007cff] font-semibold">
                  {savingsPerMonth == 0 && "£ --"}
                  {savingsPerMonth != 0 && "£ " + savingsPerMonth}
                </p>
              </div>
              <div className="flex flex-col gap-2">
                <p className="text-3xl font-kumbh text-slate-700 font-semibold">
                  Savings/Year
                </p>
                <p className="text-3xl font-kumbh text-[#007cff] font-semibold">
                  {savingsPerYear == 0 && "£ --"}
                  {savingsPerYear != 0 && "£ " + savingsPerYear}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calculator;
