import React from 'react'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import t1 from '../assets/t1.png'
import t2 from '../assets/t2.png'
import t3 from '../assets/t3.png'
import t4 from '../assets/t4.png'
const Testimonials = () => {
    const testimonialData = [
        {
            id: 0,
            image: t1,
            quote: "Thanks to your service, our restaurant's sales have skyrocketed!",
            author: 'Michael Johnson',
            role: 'Restaurant Owner',
        },
        {
            id: 1,
            image: t2,
            quote: 'The results were beyond our expectations. Highly recommended!',
            author: 'Sophia Williams',
            role: 'Catering Manager',
        },
        {
            id: 2,
            image: t3,
            quote: 'Your team helped us attract new customers and increase our revenue.',
            author: 'David Lee',
            role: 'Chef',
        },
        {
            id: 3,
            image: t4,
            quote: 'We have seen a significant improvement in customer engagement and satisfaction.',
            author: 'Emily Turner',
            role: 'Restaurant Manager',
        },
    ];


    return (
        <div name="platforms" className="w-full my-24 ">
            <div className="max-w-[1200px] mx-auto ">

                <div className='relative'>
                    <div className="flex items-center justify-between h-full w-full absolute ">
                        <div className="w-1/3 bg-white h-full" />
                        <div className="w-4/6 ml-16 bg-gray-100 h-[80%] lg:h-full" />
                    </div>
                </div>
                <div className="xl:px-20 px-8 py-20 2xl:mx-auto 2xl:container relative ">
                    <CarouselProvider naturalSlideWidth={100} infinite={true} isIntrinsicHeight={true} totalSlides={testimonialData.length} >
                        <p className="text-[2.5rem] font-bold xl:block hidden leading-tight  font-kumbh">
                            What our customers are
                            <br />
                            saying
                        </p>
                        <p className="text-5xl font-bold xl:hidden block leading-tight md:leading-10 font-kumbh">What our customers are saying</p>
                        <Slider className='' >
                            {testimonialData.map(testimonial => (
                                <Slide key={testimonial.id} index={testimonial.id - 1}>
                                    <div className="flex">
                                        <div className="mt-14 md:flex">
                                            <div className="relative md:w-1/2 sm:w-96 xl:h-96 h-80">
                                                <img src={testimonial.image} alt={`${testimonial.author}`} className="w-full h-full flex-shrink-0 object-fit object-cover  rounded" />
                                                <div className="w-24 md:flex hidden items-center justify-center absolute top-0 -mr-16 -mt-14 right-0 h-24 bg-indigo-100 rounded-full">
                                                    <img
                                                        src="https://tuk-cdn.s3.amazonaws.com/can-uploader/testimonial-svg1.svg"
                                                        alt="commas"
                                                        className="w-10 h-10 "
                                                        style={{ filter: 'grayscale(100%)' }}
                                                    />
                                                </div>

                                            </div>
                                            <div className=" md:w-1/3  xl:ml-32 md:ml-20 md:mt-0 mt-4 flex flex-col justify-between ">
                                                <div>
                                                    <p className="text-2xl font-semibold xl:leading-loose text-gray-800 font-kumbh">{testimonial.quote}</p>
                                                    <p className="text-base font-medium leading-6 mt-4 text-gray-600 font-kumbh">{testimonial.quote}</p>
                                                </div>
                                                <div className="md:mt-0 mt-8">
                                                    <p className="text-base font-medium leading-4 text-gray-800 font-kumbh">{testimonial.author}</p>
                                                    <p className="text-base leading-4 mt-2 mb-4 text-gray-600 font-kumbh">{testimonial.role}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Slide>
                            ))}
                        </Slider >
                        <div className="flex items-center mt-8">
                            <ButtonBack className="cursor-pointer hover:scale-125 duration-300 active:scale-100" role="button" aria-label="previous slide">
                                <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/testimonal-svg2.svg" className='w-5' alt="previous" />
                            </ButtonBack>

                            <ButtonNext role="button" aria-label="next slide" className="cursor-pointer ml-2 hover:scale-125 duration-300 border-[1px] border-black active:scale-100" >
                                <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/testimonial-svg3.svg" className='w-8' alt="next" />
                            </ButtonNext>
                        </div>
                    </CarouselProvider>
                </div>
            </div>


        </div>
    )
}

export default Testimonials