import React from "react";
import spoon from "../assets/spoon.png";
import box from "../assets/box.png";
import location from "../assets/location.png";
import grocries from "../assets/grocries.png";

const Services = () => {
  return (
    <div name="platforms" className="w-full my-32 overflow-x-hidden h-">
      <div className="max-w-[1200px] mx-auto ">
        <h2 className="text-3xl p-5 xl:p-0 xl:max-w-full  text-center   xl:text-[2.5rem] font-bold  font-kumbh">
          Fly Eats works with businesses like yours
        </h2>

        <div className="flex  flex-col md:flex-row gap-4 pt-20 ">
          <div className="flex justify-center rounded-xl items-center flex-col gap-3  border-slate-400 mx-auto max-w-[80%]   p-6   pb-20 min-w-[20%] hover:bg-[#007cff] hover:text-white scale-95  hover:scale-100 duration-300 my hover:border-0">
            <img
              src={spoon}
              alt="spoon"
              className="w-16 flex  fill-white text-white custom"
            />
            <div className="font-kumbh font-semibold">Restaurants</div>
            <div className="font-kumbh text-sm ">
              Experience the effortless integration of collection, delivery, and
              digital table and kiosk ordering with Fly Eats.
            </div>
          </div>
          <div className="flex flex-col rounded-xl justify-center items-center gap-3  border-slate-400 mx-auto max-w-[80%] p-6  pb-20 min-w-[20%] hover:bg-[#007cff] hover:text-white scale-95  hover:scale-100 my duration-300 custom">
            <img src={box} alt="spoon" className="w-20 flex  custom" />
            <div className="font-kumbh font-semibold">Takeaways</div>
            <div className="font-kumbh text-sm">
              Move away from third parties solutions to drasticly increase your
              revenue and to build your brand stronger.
            </div>
          </div>

          <div className="flex flex-col rounded-xl justify-center items-center gap-3  border-slate-400 mx-auto max-w-[80%] p-6  pb-20 min-w-[20%] hover:bg-[#007cff] hover:text-white  my scale-95  hover:scale-100 duration-300 custom">
            <img src={location} alt="spoon" className="w-16 flex custom" />
            <div className="font-kumbh font-semibold">Cahins</div>
            <div className="font-kumbh text-sm">
              Fly Eats is the ultimate solution tailored for multi-store chains
              and dynamic franchises.
            </div>
          </div>
          <div className="flex flex-col rounded-xl gap-3 justify-center items-center  border-slate-400 mx-auto max-w-[80%] p-6 pb-20 min-w-[20%] hover:bg-[#007cff] hover:text-white my scale-95  hover:scale-100 duration-300 custom">
            <img src={grocries} alt="spoon" className="w-16 flex custom" />
            <div className="font-kumbh font-semibold">Grocries</div>
            <div className="font-kumbh text-sm">
              Elevate your grocery business with seamless click-and-collect or
              delivery integration through Fly Eats.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
