import React from "react";
import { Link } from "react-router-dom";
const Blogs = () => {
  return (
    <section className="font-kumbh mt-8 md:mt-0" name="blogs">
      <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-sm">
          <h2 className="mb-10 md:mb-14 text-4xl font-bold ">Blogs</h2>
        </div>
        <div className="grid mb-8 lg:mb-12 lg:grid-cols-3 gap-8 h-max">
          <a
            href="/blogs/streamlining-success-the-benefits-of-digital-ordering-systems-for-restaurants.html"
            className="flex min-h-max flex-col justify-center items-start p-8 text-left border-b border-gray-200 md:p-12 lg:border-r hover:scale-105 duration-300 shadow-xl rounded-xl"
          >
            <figure>
              <blockquote className="mb-8">
                <h3 className="text-lg font-semibold mt-4">
                  Streamlining Success The Benefits of Digital Ordering Systems
                  for Restaurants
                </h3>
                <p className="mt-4">
                  In this blog, we'll explore the numerous benefits of
                  implementing a digital ordering system in restaurants.
                </p>
              </blockquote>
              <p className="text-gray-500 text-xs">Date: 12-Sep-2023</p>
            </figure>
          </a>
          <a
            href="/blogs/why-restaurant-owners-must-use-digital-ordering-systems.html"
            className="flex min-h-max flex-col justify-center items-start p-8 text-left border-b border-gray-200 md:p-12 lg:border-r hover:scale-105 duration-300 shadow-xl rounded-xl"
          >
            <figure>
              <blockquote className="mb-8">
                <h3 className="text-lg font-semibold mt-4">
                  Why Restaurant Owners Must Use Digital Ordering Systems?
                </h3>
                <p className="mt-4">
                  Why Restaurant Owners Must Use Digital Ordering Systems?
                  Digital ordering system is the need of restaurant industry now
                  a days.
                </p>
              </blockquote>
              <p className="text-gray-500 text-xs">Date: 30-Sep-2023</p>
            </figure>
          </a>
          <a
            href="/blogs/8-tips-for-restaurant-success.html"
            className="flex min-h-max flex-col justify-center items-start p-8 text-left border-b border-gray-200 md:p-12 lg:border-r hover:scale-105 duration-300 shadow-xl rounded-xl"
          >
            <figure>
              <blockquote className="mb-8">
                <h3 className="text-lg font-semibold mt-4">
                  8 Tips for Restaurant Success: How to Thrive in the
                  Competitive Food Industry
                </h3>
                <p className="mt-4">
                  Discover 8 essential strategies to ensure the success of your
                  restaurant business. From optimizing your menu to enhancing
                  customer service.
                </p>
              </blockquote>
              <p className="text-gray-500 text-xs">Date: 21-Oct-2023</p>
            </figure>
          </a>
        </div>
        <div className="text-center ">
          <a
            href="/blogs.html"
            className="py-2.5   px-5 mr-2 mb-2 text-sm font-medium focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:z-10 focus:ring-4 focus:ring-gray-200"
          >
            Show more...
          </a>
        </div>
      </div>
    </section>
  );
};

export default Blogs;
