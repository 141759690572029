import React from "react";
import About from "../components/About";
import Hero from "../components/Hero";
import Header from "../components/Header";
import Support from "../components/Support";
// import AllinOne from "../components/AllinOne";
import Pricing from "../components/Pricing";
import Footer from "../components/Footer";
import Calculator from "../components/Calculator";
import Services from "../components/Services";
import Info from "../components/Info";
import Testimonials from "../components/Testimonials";
import Blogs from "../components/Blogs";


const Main = () => {
  return (
    <div className="max-w-screen overflow-x-hidden">

      <div className="relative ">
        <div className="z-0 flex absolute -top-28 -right-[250px] h-[500px] w-[500px] lg:bg-[#007cff]/50 bg-[#007cff]/20  clip-path circle-clip rounded-full"></div>
        <div className="hidden md:flex absolute   top-80 right-28 h-[100px] w-[100px] bg-[#007cff]/60 rounded-full"></div>
        <div className=" hidden md:flex absolute top-96   right-14 h-[50px] w-[50px] bg-[#007cff]/70 rounded-full"></div>
      </div>
      <div className=" ">
        <Header />
        <Hero />
        <About />
        <Services />
        <Info />
        <Calculator />

        <Testimonials />
        {/* <AllinOne /> */}
        <Blogs />
        <Support />

        <Pricing />
        <Footer />
      </div>
    </div>
  );
};

export default Main;
