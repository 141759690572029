import React, { useState, useEffect } from "react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { Link } from "react-scroll";

const Header = () => {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);
  const handleClose = () => setNav(false);
  const [scrolling, setScrolling] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <div
        className={`w-full h-[100px]  md:h-[100px] overflow-x-hidden flex flex-row items-center justify-between   z-50 fixed ${scrolling
          ? "shadow-lg bg-[#ffffff] shadow-transition duration-300"
          : "bg-white"
          }`}
      >
        <div className=" p-5 flex items-center justify-between  w-[100%] h-full ">
          <p className="text-4xl font-bold  md:text-5xl tracking-tighter lg:ml-24 font-kumbh">
            Fly Eats.
          </p>
          <div className="hidden md:flex  items-baseline justify-center">
            <ul className="flex flex-row font-kumbh mr-5">
              <li className="">
                <Link
                  to="home"
                  smooth={true}
                  duration={500}
                  className="cursor-pointer tracking-wide text-sm hover:pb-3 duration-300   hover:border-b-[3px] hover:border-solid hover:border-[#007cff]"
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="about"
                  smooth={true}
                  offset={-200}
                  duration={500}
                  className="cursor-pointer text-sm tracking-wide hover:pb-3 duration-300  hover:border-b-[3px] hover:border-solid hover:border-[#007cff]"
                >
                  What We Do
                </Link>
              </li>
              <li>
                <Link
                  to="support"
                  smooth={true}
                  offset={-50}
                  duration={500}
                  className="cursor-pointer text-sm tracking-wide hover:pb-3 duration-300  hover:border-b-[3px] hover:border-solid hover:border-[#007cff]"
                >
                  Support
                </Link>
              </li>
              <li>
                <Link
                  to="blogs"
                  smooth={true}
                  offset={-50}
                  duration={500}
                  className="cursor-pointer text-sm tracking-wide hover:pb-3 duration-300  hover:border-b-[3px] hover:border-solid hover:border-[#007cff]"
                >
                  Blogs
                </Link>
              </li>
              <li>
                <Link
                  to="platforms"
                  smooth={true}
                  offset={-100}
                  duration={500}
                  className="cursor-pointer text-sm tracking-wide hover:pb-3 duration-300  hover:border-b-[3px] hover:border-solid hover:border-[#007cff] "
                >
                  Platforms
                </Link>
              </li>
              <li>
                <Link
                  to="pricing"
                  smooth={true}
                  offset={-50}
                  duration={500}
                  className="cursor-pointer text-sm tracking-wide hover:pb-3 duration-300  hover:border-b-[3px] hover:border-solid hover:border-[#007cff]"
                >
                  Pricing
                </Link>
              </li>
              <li>
                <a
                  href="Contact-Us.html"
                  smooth={true}
                  offset={-50}
                  duration={500}
                  className="cursor-pointer text-sm tracking-wide hover:pb-3 duration-300  hover:border-b-[3px] hover:border-solid hover:border-[#007cff]"
                >
                  Contact Us
                </a>
              </li>
            </ul>
            <button className="px-4 py-2 rounded-full  lg:mr-20 hidden md:flex text-white border-2 bg-[#007cff] border-[#007cff] hover:bg-transparent hover:text-[#007cff]  duration-300 hover:scale-105 active:scale-100">
              Talk to Sales
            </button>
          </div>

          <div
            className="md:hidden  w-10 h-10  flex justify-center"
            onClick={handleClick}
          >
            {!nav ? (
              <MenuIcon className="w-6" onClick={handleClick} />
            ) : (
              <XIcon className="w-6" onClick={handleClick} />
            )}
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center">
        <ul
          className={
            !nav
              ? " hidden"
              : "z-30 w-full p-10 fixed transform transition-all duration-300 delay-300   top-20 mt-4 rounded-xl  bg-zinc-200 "
          }
        >
          <li className="border-b-2 border-zinc-300  w-full">
            <Link
              onClick={handleClose}
              to="home"
              smooth={true}
              duration={500}
              className="cursor-pointer hover:pb-3 hover:border-b-[3px] hover:border-solid hover:border-[#007cff]"
            >
              Home
            </Link>
          </li>
          <li className="border-b-2 border-zinc-300 w-full">
            <Link
              onClick={handleClose}
              to="about"
              smooth={true}
              offset={-200}
              duration={500}
              className="cursor-pointer hover:pb-3 hover:border-b-[3px] hover:border-solid hover:border-[#007cff]"
            >
              About
            </Link>
          </li>
          <li className="border-b-2 border-zinc-300 w-full">
            <Link
              onClick={handleClose}
              to="support"
              smooth={true}
              offset={-50}
              duration={500}
              className="cursor-pointer hover:pb-3 hover:border-b-[3px] hover:border-solid hover:border-[#007cff]"
            >
              Support
            </Link>
          </li>
          <li className="border-b-2 border-zinc-300 w-full">
            <Link
              onClick={handleClose}
              to="platforms"
              smooth={true}
              offset={-100}
              duration={500}
              className="cursor-pointer hover:pb-3 hover:border-b-[3px] hover:border-solid hover:border-[#007cff]"
            >
              Platforms
            </Link>
          </li>
          <li className="border-b-2 border-zinc-300 w-full">
            <Link
              onClick={handleClose}
              to="pricing"
              smooth={true}
              offset={-50}
              duration={500}
              className="cursor-pointer hover:pb-3 hover:border-b-[3px] hover:border-solid hover:border-[#007cff]"
            >
              Pricing
            </Link>
          </li>
          <div className="flex flex-col my-4">
            <button className="px-8 py-3">Talk To Sales</button>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default Header;
