import React, { useState } from "react";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  CloudUploadIcon,
  DatabaseIcon,
  PaperAirplaneIcon,
  ServerIcon,
} from "@heroicons/react/solid";

const Hero = () => {
  const [submitMessage, setSubmitMessage] = useState("Request a Callback!"); // Initialize as true

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setSubmitMessage("Sending...");
    const name = formData.name;
    const email = formData.email;
    const phone = formData.phone;

    emailjs
      .send(
        "service_l4in3mp",
        "template_u4xyy79",
        {
          name,
          email,
          phone,
        },
        "1k26n2hCWZirXny3l"
      )
      .then((response) => {
        toast.success("Email sent successfully!");
        setFormData({
          name: "",
          email: "",
          phone: "",
        });
        setSubmitMessage("Request a Callback!");
      })
      .catch((error) => {
        console.error("Email could not be sent:", error);
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div
      name="home"
      className="w-screen md:h-screen bg-white flex flex-col justify-between overflow-x-hidden pb-5 lg:pb-0"
    >
      <ToastContainer position="top-center" autoClose={3000} />
      <div className="grid md:grid-cols-2 max-w-[1240px] m-auto ">
        <div className="flex mt-20 gap-4 px-5 py-5 lg:mt-0 flex-col justify-center lg:items-start max-w-[100%] lg:px-2 lg:py-8 ">
          <h1 className="py-3 text-5xl lg:text-5xl font-semibold  font-kumbh z-10">
            Elevating Restaurants with Advanced Digital Solutions
          </h1>

          <p className="text-md font-kumbh z-10">
            Fly Eats delivers a comprehensive restaurant management solution
            designed for seamless business operations. Our integrated suite
            includes user-friendly websites and applications to facilitate
            convenient online orders, efficient self-service kiosks, and dynamic
            marketing strategies. Coupled with our loyalty initiatives, Fly Eats
            is your partner in driving substantial business expansion
          </p>

          <button className="hidden py-3 px-6 lg:w-[50%] my-4 active:scale-100 rounded-full text-white border-2 bg-[#007cff] border-[#007cff] hover:bg-transparent hover:text-[#007cff]  duration-300 hover:scale-105">
            Get Started
          </button>
        </div>
        <div className="flex justify-center  items-center w-full">
          <div className="flex justify-center  w-[80%] lg:w-[60%] ">
            <div className=" w-full  z-10  lg:flex flex-col   bg-white rounded-xl text-center  lg:drop-shadow-2xl shadow-2xl">
              <h2 className="text-2xl font-semibold p-4 mt-2 font-kumbh ">
                Talk to our Specialist
              </h2>
              <form
                onSubmit={handleFormSubmit}
                className="flex flex-col gap-4 p-5"
              >
                <input
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                  name="name"
                  className=" px-4 py-2 border  rounded-md focus:outline-none focus:border-[#007cff]"
                  type="text"
                  placeholder="Name"
                />
                <input
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                  name="email"
                  className=" px-4 py-2 border  rounded-md focus:outline-none focus:border-[#007cff]"
                  type="email"
                  placeholder="Email Address"
                />
                <input
                  name="phone"
                  required
                  className="px-4 py-2 border rounded-md focus:outline-none focus:border-[#007cff]"
                  type="tel" // Use type "tel" for phone numbers
                  placeholder="Phone Number"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  style={{
                    "-moz-appearance": "textfield", // For Firefox
                  }}
                  onWheel={(e) => e.preventDefault()} // Disable scrolling with mouse wheel
                  value={formData.phone}
                  onChange={handleInputChange}
                />
                <div className="items-center pt-5">
                  <button
                    type="submit"
                    className="py-2 px-4 w-[80%] lg:w-[80%] active:scale-100  bg-[#007cff] text-white rounded-full select-none selection:none lg:hover:bg-transparent lg:hover:text-[#007cff] border-2 border-[#007cff] duration-300 hover:scale-105"
                  >
                    {submitMessage}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div
          className=" absolute  flex-col py-8 hidden lg:hidden lg:min-w-[760px] bottom-[5%]
              mx-1 lg:left-1/2 transform lg:-translate-x-1/2 bg-white rounded-xl text-center drop-shadow-2xl shadow-2xl"
        >
          <p>Data Services</p>
          <div className="flex justify-between flex-wrap px-4">
            <p className="flex px-4 py-2 text-slate-500">
              <CloudUploadIcon className="h-6 text-[#007cff]" /> App Security
            </p>
            <p className="flex px-4 py-2 text-slate-500">
              <DatabaseIcon className="h-6 text-[#007cff]" /> Dashboard Design
            </p>
            <p className="flex px-4 py-2 text-slate-500">
              <ServerIcon className="h-6 text-[#007cff]" /> Cloud Data
            </p>
            <p className="flex px-4 py-2 text-slate-500">
              <PaperAirplaneIcon className="h-6 text-[#007cff]" /> API
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Hero;
