import React from "react";
import {
  FaFacebook,

  FaInstagram,

  FaLinkedin,

} from "react-icons/fa";

const Footer = () => {
  return (
    <div className="w-full mt-24 bg-slate-50 py-8 px-2 overflow-x-hidden">
      <div className="max-w-[1240px] font-kumbh mx-auto grid grid-cols-2 md:grid-cols-4 border-b-2 border-gray-600 py-8">

      </div>
      <div className="flex flex-col max-w-[1240px] px-2 py-4 mx-auto justify-between sm:flex-row text-center text-gray-500">
        <p className="py-4">2022 Fly Eats LTD, All rights reserved</p>
        <div className="flex justify-center gap-7 sm:w-[300px] pt-4 text-2xl">
          <a href="https://www.facebook.com/FlyEatsLTD" target="_blank"><FaFacebook /></a>
          <a href="https://www.instagram.com/flyeatsltd" target="_blank"><FaInstagram /></a>
          <a href="https://www.linkedin.com/company/flyeats" target="_blank"><FaLinkedin /></a>


        </div>
      </div>
    </div>
  );
};

export default Footer;
