import React, { useState } from "react";

const Pricing = () => {
  const [selectedOption, setSelectedOption] = useState("AllInOne");
  const translateXOptions = {
    AllInOne: "4",
    Website: "38",
    Mobile: "72",

  };
  const pricing = {
    Yearly: 39,
    montly: 29,
    quaterly: 19,
    halfYearly: 239,
  };


  const handleToggle = (option) => {
    setSelectedOption(option);
  };
  return (
    <div name="pricing" className="w-full text-white my-24 overflow-x-hidden">
      <div className="w-full h-[1000px] lg:h-[1000px]  bg-slate-900 absolute mix-blend-overlay"></div>
      <div className="max-w-[80%] mx-auto">
        <div className="text-center py-8 text-slate-300">
          <h2 className="text-2xl uppercase">Pricing</h2>
          <h3 className="text-4xl font-bold text-white py-8">
            Choose the plan that works for your restaurant
          </h3>
        </div>
      </div>
      <div className="max-w-[90%] mx-auto mt-5">
        <div className="flex lg:hidden justify-center max-w-[25rem] m-auto mb-8 lg:mb-16">
          <div className="relative flex w-full p-1 bg-white rounded-full">
            <span
              className={`absolute inset-0 m-1 pointer-events-none transition-transform duration-150 ease-in-out transform translate-x-[${translateXOptions[selectedOption]}%]`}
              aria-hidden="true"
            >
              <span
                className={`absolute inset-0 w-1/4 bg-[#007cff] rounded-full shadow-sm shadow-indigo-950/10`}
              ></span>
            </span>

            <button
              className={`relative flex-1 text-sm font-medium h-8 rounded-full focus-visible:outline-none focus-visible:ring focus-visible:ring-[#007cff]  transition-colors duration-150 ease-in-out ${selectedOption === "AllInOne" ? "text-white" : "text-slate-500"
                }`}
              onClick={() => handleToggle("AllInOne")}
              aria-pressed={selectedOption === "AllInOne"}
            >
              All in One
              <span
                className={
                  selectedOption === "AllInOne" ? "text-white" : "text-slate-400"
                }
              ></span>
            </button>

            <button
              className={`relative flex-1 text-sm font-medium h-8 rounded-full focus-visible:outline-none focus-visible:ring focus-visible:ring-[#007cff]  transition-colors duration-150 ease-in-out ${selectedOption === "Website" ? "text-white" : "text-slate-500"
                }`}
              onClick={() => handleToggle("Website")}
              aria-pressed={selectedOption === "Website"}
            >
              Website
            </button>
            <button
              className={`relative flex-1 text-sm font-medium h-8 rounded-full focus-visible:outline-none focus-visible:ring focus-visible:ring-[#007cff]  transition-colors duration-150 ease-in-out ${selectedOption === "Mobile"
                ? "text-Darkblue"
                : "text-slate-500"
                }`}
              onClick={() => handleToggle("Mobile")}
              aria-pressed={selectedOption === "Mobile"}
            >
              Mobile
            </button>
          </div>
        </div>

        <div className="max-w-sm mx-auto grid gap-10 lg:grid-cols-3 items-start lg:max-w-none">
          {/* mobile pricing tab  */}

          {/* <!-- Mobile Pricing tab 1 --> */}
          {selectedOption === "Website" ? (
            <div className="lg:hidden h-full">
              <div className="relative flex flex-col h-full p-6 rounded-2xl bg-white  border border-slate-200  shadow shadow-slate-950/5">
                <div className="mb-5">
                  <div className="text-slate-900  font-bold mb-1 text-center">
                    Website Ordering
                  </div>
                  <div className="text-slate-900  font-semibold mb-1 text-center">
                    Receive Orders Directly from your own Website
                  </div>
                  <div className="flex justify-center items-baseline mb-2 mt-5">
                    <span className="text-slate-900 text-center font-bold text-5xl">
                      £{pricing.montly}
                    </span>
                    <div className="text-slate-900  ">/mo</div>
                  </div>
                  <div className="text-slate-900 text-center">
                    Billed annual basis per site. £39 per month if billed
                    monthly
                  </div>
                </div>
                <div className="text-slate-900  font-medium mb-3">
                  Includes:
                </div>
                <ul className="text-slate-600  text-sm space-y-0  grow">
                  <li
                    style={{ padding: "0.8rem" }}
                    className="flex items-center"
                  >
                    <svg
                      className="w-3 h-3 fill-emerald-500 mr-3 shrink-0"
                      viewBox="0 0 12 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>User Friendly Interface</span>
                  </li>
                  <li
                    style={{ padding: "0.8rem" }}
                    className="flex items-center"
                  >
                    <svg
                      className="w-3 h-3 fill-emerald-500 mr-3 shrink-0"
                      viewBox="0 0 12 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Branded Website</span>
                  </li>
                  <li
                    style={{ padding: "0.8rem" }}
                    className="flex items-center"
                  >
                    <svg
                      className="w-3 h-3 fill-emerald-500 mr-3 shrink-0"
                      viewBox="0 0 12 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Menu Managment</span>
                  </li>
                  <li
                    style={{ padding: "0.8rem" }}
                    className="flex items-center"
                  >
                    <svg
                      className="w-3 h-3 fill-emerald-500 mr-3 shrink-0"
                      viewBox="0 0 12 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Reporting</span>
                  </li>
                </ul>
              </div>
            </div>
          ) : (
            ""
          )}

          {selectedOption === "Mobile" ? (
            <div className=" lg:hidden h-full">
              <div className="relative flex flex-col h-full p-6 rounded-2xl bg-white  border border-slate-200  shadow shadow-slate-950/5">
                <div className="mb-5">
                  <div className="text-slate-900  font-bold mb-1 text-center">
                    Mobile Applications
                  </div>
                  <div className="text-slate-900  font-semibold mb-1 text-center">
                    Receive Orders from WhiteLabel/Mobile Applications
                  </div>
                  <div className="flex justify-center items-baseline mb-2 mt-5">
                    <span className="text-slate-900 text-center font-bold text-5xl">
                      £{pricing.quaterly}
                    </span>
                    <div className="text-slate-900  ">/mo</div>
                  </div>
                  <div className="text-slate-900 text-center">
                    Billed annual basis per site. £29 per month if billed
                    monthly
                  </div>
                </div>
                <div className="text-slate-900  font-medium mb-3">
                  Includes:
                </div>
                <ul className="text-slate-600  text-sm space-y-0 grow">
                  <li
                    style={{ padding: "0.8rem" }}
                    className="flex items-center"
                  >
                    <svg
                      className="w-3 h-3 fill-emerald-500 mr-3 shrink-0"
                      viewBox="0 0 12 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>User Friendly Interface</span>
                  </li>
                  <li
                    style={{ padding: "0.8rem" }}
                    className="flex items-center"
                  >
                    <svg
                      className="w-3 h-3 fill-emerald-500 mr-3 shrink-0"
                      viewBox="0 0 12 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Branded Applications</span>
                  </li>
                  <li
                    style={{ padding: "0.8rem" }}
                    className="flex items-center"
                  >
                    <svg
                      className="w-3 h-3 fill-emerald-500 mr-3 shrink-0"
                      viewBox="0 0 12 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Menu Managment</span>
                  </li>
                  <li
                    style={{ padding: "0.8rem" }}
                    className="flex items-center"
                  >
                    <svg
                      className="w-3 h-3 fill-emerald-500 mr-3 shrink-0"
                      viewBox="0 0 12 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Reporting</span>
                  </li>
                </ul>
              </div>
            </div>
          ) : (
            ""
          )}

          {selectedOption === "AllInOne" ? (
            <div className="lg:hidden h-full">
              <div className="relative flex flex-col h-full p-6 rounded-2xl bg-white  border border-slate-200  shadow shadow-slate-950/5">
                <div className="absolute top-0 right-0 mr-6 -mt-4">
                  <div className="inline-flex items-center text-xs font-semibold py-1.5 px-3 bg-emerald-500 text-white rounded-full shadow-sm shadow-slate-950/5">
                    Most Popular
                  </div>
                </div>
                <div className="mb-5">
                  <div className="text-slate-900  font-bold mb-1 text-center">
                    Website & Mobile Applications
                  </div>
                  <div className="text-slate-900  font-semibold mb-1 text-center">
                    Receive Orders from both website & Applications
                  </div>
                  <div className="flex justify-center items-baseline mb-2 mt-5">
                    <span className="text-slate-900 text-center font-bold text-5xl">
                      £{pricing.Yearly}
                    </span>
                    <div className="text-slate-900  ">/mo</div>
                  </div>
                  <div className="text-slate-900 text-center">
                    Billed annual basis per business. £50 per month if billed
                    monthly
                  </div>
                </div>
                <div className="text-slate-900  font-medium mb-3">
                  Includes:
                </div>
                <ul className="text-slate-600  text-sm space-y-0 grow">
                  <li
                    style={{ padding: "0.8rem" }}
                    className="flex items-center"
                  >
                    <svg
                      className="w-3 h-3 fill-emerald-500 mr-3 shrink-0"
                      viewBox="0 0 12 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>User Friendly Interface</span>
                  </li>
                  <li
                    style={{ padding: "0.8rem" }}
                    className="flex items-center"
                  >
                    <svg
                      className="w-3 h-3 fill-emerald-500 mr-3 shrink-0"
                      viewBox="0 0 12 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Branded Website & Applications</span>
                  </li>
                  <li
                    style={{ padding: "0.8rem" }}
                    className="flex items-center"
                  >
                    <svg
                      className="w-3 h-3 fill-emerald-500 mr-3 shrink-0"
                      viewBox="0 0 12 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Menu Managment</span>
                  </li>
                  <li
                    style={{ padding: "0.8rem" }}
                    className="flex items-center"
                  >
                    <svg
                      className="w-3 h-3 fill-emerald-500 mr-3 shrink-0"
                      viewBox="0 0 12 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Reporting</span>
                  </li>
                </ul>
              </div>
            </div>
          ) : (
            ""
          )}

          {/* <!-- Pricing tab 1 --> */}
          <div className="hidden lg:flex h-full">
            <div className="relative flex flex-col h-full p-6 rounded-2xl bg-white  border border-slate-200  shadow shadow-slate-950/5">
              <div className="mb-5">
                <div className="text-slate-900  font-bold mb-1 text-center">
                  Website Ordering
                </div>
                <div className="text-slate-900  font-semibold mb-1 text-center">
                  Receive Orders Directly from your own Website
                </div>
                <div className="flex justify-center items-baseline mb-2 mt-5">
                  <span className="text-slate-900 text-center font-bold text-5xl">
                    £{pricing.montly}
                  </span>
                  <div className="text-slate-900  ">/mo</div>
                </div>
                <div className="text-slate-900 text-center">
                  Billed annual basis per site. £39 per month if billed monthly
                </div>
              </div>
              <div className="text-slate-900  font-medium mb-3">Includes:</div>
              <ul className="text-slate-600  text-sm space-y-0  grow">
                <li style={{ padding: "0.8rem" }} className="flex items-center">
                  <svg
                    className="w-3 h-3 fill-emerald-500 mr-3 shrink-0"
                    viewBox="0 0 12 12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>User Friendly Interface</span>
                </li>
                <li style={{ padding: "0.8rem" }} className="flex items-center">
                  <svg
                    className="w-3 h-3 fill-emerald-500 mr-3 shrink-0"
                    viewBox="0 0 12 12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>Branded Website</span>
                </li>
                <li style={{ padding: "0.8rem" }} className="flex items-center">
                  <svg
                    className="w-3 h-3 fill-emerald-500 mr-3 shrink-0"
                    viewBox="0 0 12 12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>Menu Managment</span>
                </li>
                <li style={{ padding: "0.8rem" }} className="flex items-center">
                  <svg
                    className="w-3 h-3 fill-emerald-500 mr-3 shrink-0"
                    viewBox="0 0 12 12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>Reporting</span>
                </li>
              </ul>
            </div>
          </div>

          <div className="hidden lg:flex h-full">
            <div className="relative flex flex-col h-full p-6 rounded-2xl bg-white  border border-slate-200  shadow shadow-slate-950/5">
              <div className="mb-5">
                <div className="text-slate-900  font-bold mb-1 text-center">
                  Mobile Applications
                </div>
                <div className="text-slate-900  font-semibold mb-1 text-center">
                  Receive Orders from WhiteLabel/Mobile Applications
                </div>
                <div className="flex justify-center items-baseline mb-2 mt-5">
                  <span className="text-slate-900 text-center font-bold text-5xl">
                    £{pricing.quaterly}
                  </span>
                  <div className="text-slate-900  ">/mo</div>
                </div>
                <div className="text-slate-900 text-center">
                  Billed annual basis per site. £29 per month if billed monthly
                </div>
              </div>
              <div className="text-slate-900  font-medium mb-3">Includes:</div>
              <ul className="text-slate-600  text-sm space-y-0 grow">
                <li style={{ padding: "0.8rem" }} className="flex items-center">
                  <svg
                    className="w-3 h-3 fill-emerald-500 mr-3 shrink-0"
                    viewBox="0 0 12 12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>User Friendly Interface</span>
                </li>
                <li style={{ padding: "0.8rem" }} className="flex items-center">
                  <svg
                    className="w-3 h-3 fill-emerald-500 mr-3 shrink-0"
                    viewBox="0 0 12 12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>Branded Applications</span>
                </li>
                <li style={{ padding: "0.8rem" }} className="flex items-center">
                  <svg
                    className="w-3 h-3 fill-emerald-500 mr-3 shrink-0"
                    viewBox="0 0 12 12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>Menu Managment</span>
                </li>
                <li style={{ padding: "0.8rem" }} className="flex items-center">
                  <svg
                    className="w-3 h-3 fill-emerald-500 mr-3 shrink-0"
                    viewBox="0 0 12 12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>Reporting</span>
                </li>
              </ul>
            </div>
          </div>
          {/*<div className="hidden lg:flex h-full">
            <div className="relative flex flex-col h-full p-6 rounded-2xl bg-white  border border-slate-200  shadow shadow-slate-950/5">
              <div className="mb-5">
                <div className="text-slate-900  text-center font-semibold mb-1">
                  Half Yearly
                </div>
                <div className="flex justify-center items-baseline mb-2 mt-5">
                  <span className="text-slate-900  font-bold text-5xl">
                    {pricing.halfYearly}$
                  </span>
                </div>
                <div className="text-sm text-slate-500 mb-5">
                  There are many variations available, but the majority have
                  suffered.
                </div>
                <a
                  className="w-full inline-flex justify-center whitespace-nowrap rounded-lg bg-[#007cff] px-3.5 py-2.5 text-sm font-medium text-white shadow-sm shadow-indigo-950/10 hover:bg-[#007cff] focus-visible:outline-none focus-visible:ring focus-visible:ring-[#007cff]  transition-colors duration-150"
                  href="#0"
                >
                  Purchase Plan
                </a>
              </div>
              <div className="text-slate-900  font-medium mb-3">Includes:</div>
              <ul className="text-slate-600  text-sm space-y-0 grow">
                <li style={{ padding: "0.8rem" }} className="flex items-center">
                  <svg
                    className="w-3 h-3 fill-emerald-500 mr-3 shrink-0"
                    viewBox="0 0 12 12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>Unlimited placeholder texts</span>
                </li>
                <li style={{ padding: "0.8rem" }} className="flex items-center">
                  <svg
                    className="w-3 h-3 fill-emerald-500 mr-3 shrink-0"
                    viewBox="0 0 12 12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>Consectetur adipiscing elit</span>
                </li>
                <li style={{ padding: "0.8rem" }} className="flex items-center">
                  <svg
                    className="w-3 h-3 fill-emerald-500 mr-3 shrink-0"
                    viewBox="0 0 12 12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>Excepteur sint occaecat cupidatat</span>
                </li>
                <li style={{ padding: "0.8rem" }} className="flex items-center">
                  <svg
                    className="w-3 h-3 fill-emerald-500 mr-3 shrink-0"
                    viewBox="0 0 12 12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>Officia deserunt mollit anim</span>
                </li>
                <li style={{ padding: "0.8rem" }} className="flex items-center">
                  <svg
                    className="w-3 h-3 fill-emerald-500 mr-3 shrink-0"
                    viewBox="0 0 12 12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>Predefined chunks as necessary</span>
                </li>
              </ul>
            </div>
          </div>*/}
          {/* <!-- Pricing tab 2 --> */}
          <div className=" hidden lg:flex h-full">
            <div className="relative flex flex-col h-full p-6 rounded-2xl bg-white  border border-slate-200  shadow shadow-slate-950/5">
              <div className="absolute top-0 right-0 mr-6 -mt-4">
                <div className="inline-flex items-center text-xs font-semibold py-1.5 px-3 bg-emerald-500 text-white rounded-full shadow-sm shadow-slate-950/5">
                  Most Popular
                </div>
              </div>
              <div className="mb-5">
                <div className="text-slate-900  font-bold mb-1 text-center">
                  Website & Mobile Applications
                </div>
                <div className="text-slate-900  font-semibold mb-1 text-center">
                  Receive Orders from both website & Applications
                </div>
                <div className="flex justify-center items-baseline mb-2 mt-5">
                  <span className="text-slate-900 text-center font-bold text-5xl">
                    £{pricing.Yearly}
                  </span>
                  <div className="text-slate-900  ">/mo</div>
                </div>
                <div className="text-slate-900 text-center">
                  Billed annual basis per business. £50 per month if billed
                  monthly
                </div>
              </div>
              <div className="text-slate-900  font-medium mb-3">Includes:</div>
              <ul className="text-slate-600  text-sm space-y-0 grow">
                <li style={{ padding: "0.8rem" }} className="flex items-center">
                  <svg
                    className="w-3 h-3 fill-emerald-500 mr-3 shrink-0"
                    viewBox="0 0 12 12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>User Friendly Interface</span>
                </li>
                <li style={{ padding: "0.8rem" }} className="flex items-center">
                  <svg
                    className="w-3 h-3 fill-emerald-500 mr-3 shrink-0"
                    viewBox="0 0 12 12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>Branded Website & Applications</span>
                </li>
                <li style={{ padding: "0.8rem" }} className="flex items-center">
                  <svg
                    className="w-3 h-3 fill-emerald-500 mr-3 shrink-0"
                    viewBox="0 0 12 12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>Menu Managment</span>
                </li>
                <li style={{ padding: "0.8rem" }} className="flex items-center">
                  <svg
                    className="w-3 h-3 fill-emerald-500 mr-3 shrink-0"
                    viewBox="0 0 12 12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>Reporting</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
