import React from "react";
import img from "../assets/info.png";

const Info = () => {
  return (
    <div name="platforms" className="w-full my-32 overflow-x-hidden">
      <div className="max-w-[1200px] mx-auto ">
        <div className="flex flex-col md:flex-row gap-2 items-center justify-center mt-14  p-5">
          {/* left  */}
          <div className="md:max-w-[50%] flex flex-col gap-8 pr-15 ">
            <p className="font-kumbh"> A partner in your success</p>
            <p className="font-kumbh text-4xl font-semibold">
              More Than Software: Partnering for Your Long Term Success
            </p>
            <p className="font-kumbh ">
              At Fly Eats, our focus goes beyond software sales. We are
              dedicated to nurturing thriving food businesses. We collaborate
              closely with each of our customers to ensure their success, no
              matter what it requires.
            </p>
            <p className="font-kumbh ">
              Our relentless customer success team is committed to assisting Fly
              Eats customers in their pursuit of innovation and excellence.
              Simultaneously, our Managed Marketing team is dedicated to
              attracting new customers to your establishment's doorstep.
            </p>
          </div>

          {/* right  */}
          <div className="w-[90vw] md:pl-10 mt-8 mr-3 md:mr-0  md:mt-0">
            <img src={img} alt="img" className="w-full" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info;
