import React from "react";
import supportImg from "../assets/support.jpg";
// import { PhoneIcon, ArrowSmRightIcon } from "@heroicons/react/outline";
// import { ChipIcon, SupportIcon } from "@heroicons/react/solid";

const Support = () => {
  return (
    <div name="support" className="w-full my-32 overflow-x-hidden">
      <div className="max-w-[1200px] mx-auto ">
        <div className="flex flex-col md:flex-row gap-2 items-center justify-center mt-14  p-5">
          {/* left  */}
          <div className="w-[80vw] md:pl-10 mt-8    md:mt-0">
            <img src={supportImg} alt="img" className="w-full" />
          </div>

          {/* right  */}

          <div className="md:max-w-[50%] flex flex-col pl-8 gap-8  ">
            <p className="font-kumbh"> </p>
            <p className="font-kumbh text-4xl font-semibold">
              The support you need, whenever you need it
            </p>
            <p className="font-kumbh ">
              With Fly Eats Now, you’ll never feel like you’re going it alone.
            </p>
            <p className="font-kumbh ">
              We provide round-the-clock support, as well as personalized,
              one-on-one onboarding and unlimited training and coaching. Request
              a Callback
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;
